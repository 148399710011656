<template>
  <Header></Header>
  <div class="clueFormContainer text-sm" v-if="userLogged">
    <div class="fixed top-16 left-[10%] z-[100]"><HistoryBack /></div>
    <div class="flex flex-col items-center">
      <div class="pt-6 mt-16"> {{ getTitle() }}</div>
      <div class="textGradient" v-if="!Format.IsNull(tenantName)">{{ capitalizeFirstLetter(tenantName) }}</div>
      <div class="textGradient">{{ getSport() }}</div>
      <div class="wrapperClueW">
        <span v-if="!inTaykusMode">
          <div class="search-container relative w-full mt-4">
            <MainSearchForm
              :allowNearMe="false"
              :showSearchButton="false"
              :searchTenantEnabled="false"
              @locationSelected="handleLocationSelected"
              @tenantSelected="handleTenantSelected"
            />
          </div>
          <div class="flex justify-between">
            <div class="filterClue p-4 altBackground mt-6 br16 w-72">
              <InputPrice 
                v-model="price"
                :readonly = "false"
              />
            </div>
            <div class="filterClue p-4 altBackground mt-6 m br16 w-72">
              <div class="mb-4">{{ $t("duration") }}</div>
              <div class="flex w-full items-center">
                <div class="w-2/3">
                  <InputText v-model="stringDuration" 
                  :pattern="pattern"
                  formnovalidate
                />
                </div><div class="w-1/3 ml-4"> {{  $t("minute_shortened") }}</div>
                </div>
            </div>
          </div>
          <div class="filterClue p-4 altBackground mt-6 br16">
            <SportSelector v-model="sport" />
          </div>
          <div v-if="sport === Sport.PADEL" class="filterClue p-4 altBackground mt-6 br16">
            <ProviderSelector v-model="levelProvider"/>
          </div>
          <div v-if="sport === Sport.PADEL" class="filterClue p-4 altBackground mt-6 br16">
            <FilterSelector 
            :sportMode="sport" 
            :levelProvider="levelProvider"
            :competitive="competitive"
            v-model="filter" />
          </div>
        </span>
        <div>
          <GenderSelector
            cssClass="filterClue p-4 altBackground mt-6 br16"
            v-model="gender"
          />
        </div>
        <div>
          <LevelSelector
            v-if="levelLoaded"
            cssClass="filterClue p-4 altBackground mt-6 br16"
            :level="level"
            v-model="competitive"
            @sendLevels="handleSendLevels"
          />
        </div>
        <div v-if="resources.length > 0" class="altBackground p-4 mt-6 br16">
          <div class="mb-4">{{ $t("price_and_duration") }}</div>
          <ResourcePrice
            :showTitle="false"
            :resources="resources"
            :groupResources="true"
            :date="date"
            :sport="sport"
            :checkoutOnDurationSelected="true"
            @priceSelected="priceSelected"
          />
        </div>
        <h5 v-if="error" class="error-message text-center mt-8">{{ error }}</h5>
          <div class="flex-col w-full input-component sm:justify-center mt-8 mb-14">
            <input
              class="flex search-btn sm:box-border sm:w-full sm:flex sm:flex-row sm:justify-center sm:items-center sm:p-0 sm:h-12"
              type="button"
              :value="$t('create_match')"
              @click="createMatch()"
              :disabled="hasErrors"
              :class="{ 'button-disabled': hasErrors }"
            />
            <p v-if="hasErrors && !inTaykusMode" class="flex error-message text-center justify-center">{{ $t("complete_location_price_and_duration") }}</p>
          </div>
      </div>
    </div>
  </div>
  <Transition>
      <PopUpLight
        v-if="showGenderLevel"
        @closed="closeGenderLevel()"
        :showClose="false"
      >
        <UpdateUser
          :customSport="sport!"
          @closeGenderLevel="closeGenderLevel()"
        />
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showHasGender"
        :showClose="true"
        @closed="initRedPadelLevel"
      >
        <div class="mt-8">
          <UserGenderSelector @selectionChanged="saveGenderChanges" />
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showIframe" 
        @closed="closeRedpadelIframe"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          <iframe class="iframe" title="redPadel login" frameborder="0" :src="iframeSrc"></iframe>
        </div>
      </PopUpLight>
    </Transition>
    <Transition>
      <PopUpLight v-if="showRedpadelError" 
        @closed="showRedpadelError= false"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          {{ $t("oops_technical_problems_try_again_in_a_few_minutes") }}
        </div>
      </PopUpLight>
    </Transition>
</template>

<script lang="ts">
import { BookingType, Category, Gender, LevelProvider, MIN_LEVEL, NO_LEVEL, Sport } from "@/enum/constants";
//import { mapFilterAttributes } from "@/helpers/dataHelper"; //until activate soccer
import { beautifyDate, setTimeToDate, formatDate, capitalizeFirstLetter, formatTime } from "@/helpers/dateHelper";
import { Format } from "@/helpers/formatHelper";
import { defineComponent } from "vue";
import GenderSelector from "@/components/form/genderSelector.vue";
import LevelSelector from "@/components/form/levelSelector.vue";
import InputPrice from "@/components/form/inputPrice.vue";
import InputText from "@/components/form/inputText.vue";
import ResourcePrice from "@/components/booking/resourcePrice.vue";
import { BookingApi } from "@/service/BookingApi";
import { customGlobalError } from "@/helpers/errorHelper";
import { BookingHelper } from "@/helpers/bookingHelper";
import CustomerHelper from "@/helpers/customerHelper";
import store from "../store";
import MainSearchForm from "@/components/search/mainSearchForm.vue";
import FilterSelector from "@/components/form/filterSelector.vue";
import SportSelector from "@/components/form/sportSelector.vue";
import Header from "@/components/header/header.vue";
import { CustomerApi } from "@/service/CustomerApi";
import MatchHelper from "@/helpers/matchtHelper";
import LocationHelper from "@/helpers/locationHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import { Customer, LinkLevelProvider } from "models";
import HistoryBack from "@/components/form/historyBack.vue";
import { TenantHelper } from "@/helpers/tenantHelper";
import { MatchFilter } from "@/custom-models/matchFilter";
import { getSportLabel } from "@/helpers/dataHelper";
import { selectedPrice } from "@/custom-models/selectedPrice";
import ProviderSelector from "@/components/match/providerSelector.vue"
import PopUpLight from "@/components/popup/popupLight.vue";
import UpdateUser from "@/components/match/updateUser.vue";
import { LevelProviderRedirect } from "models";
import UserGenderSelector from "@/components/form/userGenderSelector.vue";

type SportLevel = {
    sport: {
        code: number;
        name: string;
    };
    victories: number;
    ties: number;
    defeats: number;
    level: number;
    confidence: number;
    levels: {
        levelProvider: {
            code: number;
            name: string;
        };
        level: number;
    }[];
}; // TODO hasta que tenga el modelo del back

export default defineComponent({
  name: "matchCreationview",
  components: {
    GenderSelector,
    LevelSelector,
    ResourcePrice,
    MainSearchForm,
    FilterSelector,
    SportSelector,
    Header,
    InputPrice,
    InputText,
    HistoryBack,
    ProviderSelector,
    UpdateUser,
    PopUpLight,
    UserGenderSelector
  },
  data() {
    return {
      inTaykusMode: false,
      date: "",
      hour: "",
      filter: { type: 1, subType: 0, size: 0 },
      sport: null as number | null,
      category: Category.MATCH,
      gender: Gender.OPEN,
      competitive: true,
      price: { price: 0, currency: "USD" },
      duration: 0,
      cordX: "",
      cordY: "",
      locationTenantId: "",
      level: 0 as number,
      resources: [],
      user: {} as Customer,
      tenantId: "",
      tenantName: "",
      error: "",
      booking: null as any,
      allLevels: [] as SportLevel[],
      userLogged: false,
      locName: "",
      Format: Format,
      pattern: /^[0-9]+$/,
      levelLoaded: false as boolean,
      minLevel: 0 as number,
      maxLevel: 0 as number,
      tenantInfo: {} as any,
      groupedResources: [],
      loaded: false,
      Sport: Sport,
      showGenderLevel: false,
      hasGender: false,
      levelProvider: undefined as number | undefined,
      showIframe: false,
      showRedpadelError: false,
      showHasGender: false,
      iframeSrc:undefined as string | undefined,
      socialRating: 0 as number,
    };
  },
  computed: {
    stringDuration: {
      get(): string {
        return this.duration.toString();
      },
      set(value: string): void {
        this.duration = parseInt(value, 10);
        if (isNaN(this.duration)) {
          this.duration = 0;
        }
      },
    },
    hasErrors(): boolean {
      return  Format.IsNull(this.duration) || this.duration <= 0 ||
              ( !this.inTaykusMode && (Format.IsNull(this.price) || this.price.price <= 0) ||
                !Format.IsNull(this.error) ||
                (!this.inTaykusMode && (!this.cordX || !this.cordY))
              )
    },
  },
  created() {
    this.userLogged = CustomerHelper.isUserLogged();
    this.tenantId = this.$route.query.id as string;
    this.tenantName = this.$route.query.name as string;
    this.date = this.$route.query.date as string;
    this.sport = parseInt(this.$route.query.sport as string);
    if(this.$route.query.hour){
      this.hour = this.$route.query.hour as string;
    }
    this.locName = this.$route.query.locName as string;
    this.cordX = this.$route.query.cordX as string;
    this.cordY = this.$route.query.cordY as string;
    this.date = formatDate(setTimeToDate(new Date(this.date), this.hour))
    if (!Format.IsNull(this.$route.query.id)) {
      this.inTaykusMode = true;
    }
  },
  async mounted() {
    if (window.location.href.includes("match")) {
      document.body.style.overflowY = "scroll";
    }

    let user: any = localStorage.getItem("user");

    if (user === null || user === "") {
      store.commit("openLogin", { goToUrl: this.$route.fullPath });
      return;
    }
    this.user = JSON.parse(user);
    if (!Format.IsNull(this.user.gender?.code)) {
      this.gender = this.user.gender?.code ?? Gender.OPEN;
    }
    this.hasGender = await CustomerHelper.hasGender();
    const results = await CustomerApi.getCustomerResults();
    if (results.data) {
      this.allLevels = results.data;
      this.levelProvider = MatchHelper.getSportOrFirstLevelProviderCode(this.sport!, this.allLevels);//each sport can get is own provider
      this.level = MatchHelper.getLevelBySportAndProvider(this.allLevels, this.sport!, this.levelProvider);
    }
    this.levelLoaded = true;

    if (this.inTaykusMode) {
      const request: any = {
        tenantId: this.tenantId,
        date: this.date,
        sport: this.sport,
        type: BookingType.MATCH,
        category: Category.MATCH,
        levelProvider: this.levelProvider,
      };
      this.tenantInfo.tenant = await this.loadTenantInfo();
      const response = await BookingApi.tenantAvailability(request); // load prices

      this.resources = response.data.resources;
      if (this.resources.length == 0) {
        customGlobalError(this.$t("search_no_resources_found"));
        this.error = this.$t("search_no_resources_found");
        return;
      }
    }
    this.loaded = true;
    this.fixIphoneHeight();
  },
  watch: {
    sport() {
      if(this.sport != Sport.PADEL)
      {
        this.levelProvider = LevelProvider.PANDORA;
      }
      if(this.allLevels && this.allLevels.length > 0){
        this.levelProvider != null ? this.checkLeveling(): null;
      }
    },
    levelProvider(){
      if(this.allLevels && this.allLevels.length > 0){
        this.sport != null ? this.checkLeveling(): null;
      }
    }
  },
  methods: {
    capitalizeFirstLetter,
    async linkRedPadelAccount(linkLevelProvider: LinkLevelProvider){
      try {
        await CustomerHelper.linkLevelProviderAccount(linkLevelProvider, LevelProvider.REDPADEL);
        this.level = this.socialRating;
        const results = await CustomerApi.getCustomerResults();
        if (results.data) {
          this.allLevels = results.data;
        }
      } catch (error) {
        this.showRedpadelError = true;
      }
    },
    closeRedpadelIframe(){
      this.showIframe = false;
      window.removeEventListener("message", this.redPadelListener);
    },
    async closeGenderLevel() {
      this.showGenderLevel = false;
      const results = await CustomerApi.getCustomerResults();
      if (results.data) {
        this.allLevels = results.data;
        this.level = MatchHelper.getLevelBySportAndProvider(this.allLevels, this.sport!, this.levelProvider!)
      }
    },
    handleSendLevels(initLevel: number, endLevel: number){
      this.minLevel = initLevel;
      this.maxLevel = endLevel
    },
    getTitle() {
      if (this.inTaykusMode) {
        this.date = formatDate(setTimeToDate(
          new Date(this.date),
          this.hour
        )).toString();
        return beautifyDate(this.date, true, false);
      }
      return this.$t("i_already_have_the_court");
    },
    getSport() {
      return this.capitalizeFirstLetter(this.$t(getSportLabel(this.sport!)));
    },
    checkLeveling() {
      this.level = MatchHelper.setLevel(this.sport!, this.levelProvider!, this.allLevels);
      if(this.level === NO_LEVEL){
        this.levelProvider === LevelProvider.PANDORA ? this.showGenderLevel = true : this.sendRedpadelLeveling();
      }
    },
    async initRedPadelLevel(){
      this.showHasGender = false;
      if(this.hasGender){
        const response: LevelProviderRedirect | null = await CustomerHelper.getProviderRedirectURL(LevelProvider.REDPADEL);
        if(response){
          this.iframeSrc = response.redirectURL;
          this.showIframe = true;
          window.addEventListener("message", this.redPadelListener);
        }
      }
    },
    async sendRedpadelLeveling(){
      this.hasGender = await CustomerHelper.hasGender();
      if(!this.hasGender){
        this.showHasGender = true;
        return;
      }
      this.initRedPadelLevel();
    },
    async redPadelListener(event: any) {
      window.removeEventListener("message", this.redPadelListener);
      const resultData = event.data;
      this.showIframe = false;
      if(resultData.status){
        if(resultData.socialRating){
          this.socialRating = Number(resultData.socialRating);
        }
        const linkLevelProvider: LinkLevelProvider = {
          accountId: resultData.user_id,
        };
        await this.linkRedPadelAccount(linkLevelProvider);
      }else{
        this.showRedpadelError = true;
      }
    },
    async saveGenderChanges(gender: number) {
      const user = await CustomerHelper.updateGender(gender);
      if (user) {
        CustomerHelper.updateStorageUser(user);
        this.hasGender = true;
        this.initRedPadelLevel();
      }
      this.showHasGender = false;
    },
    async loadTenantInfo(){
      const response = await TenantHelper.findTenantByIdFromPandora(this.tenantId);
      if (Format.IsNull(response)) {
        customGlobalError(this.$t("search_no_resources_found"), "/");
      }
      return response;
    },
    async createMatch() {
      this.error = "";
      if (this.inTaykusMode) {
        return await this.createMatchInTaykus();
      }
      return await this.createMatchNotInTaykus();
    },
    async createMatchInTaykus() {
      if (this.booking === null) {
        this.error = this.$t("please_select_a_resource_and_a_price");
        return;
      }
      const booking: any = this.booking;
      //let attributes: MatchFilter = mapFilterAttributes(this.sport, booking.attributes); //until activate soccer
      let attributes: MatchFilter = booking.attributes as MatchFilter
      if(this.sport === Sport.SOCCER){
        delete attributes.wall;
      }else{
        delete attributes.surface;
      }
      var matchRequest: any = {
        tenantId: this.tenantId,
        origin: BookingHelper.getOrigin(),
        start: this.date,
        sport: this.sport,
        duration: booking.duration,
        resourceId: booking.resourceId as number,
        gender: this.gender,        
        competitive: this.competitive,
        type: this.filter.type,
        players:[],
      };
      var request: any = {
        resourceId: booking.resourceId as number,
        duration: booking.duration,
        price: booking.price,
        sport: booking.sport,
        date: this.date,
        tenant: this.tenantName,
        tenantId: this.tenantId,
        matchRequest: matchRequest,
        gender: { code: this.gender },
        type: { code: Category.MATCH },
        minLevel: this.minLevel,
        maxLevel: this.maxLevel,
        allowedPaymentMethods: this.tenantInfo.tenant.allowedPaymentMethods,
        attributes: attributes,
      };
      this.$router.push({
        path: "checkout",
        query: { pl: btoa(JSON.stringify(request)) },
      });
    },
    async createMatchNotInTaykus() {
      if (
        this.duration === null ||
        isNaN(this.duration) ||
        this.duration <= 0
      ) {
        this.error = this.$t("please_select_avalid_duration");
        return;
      }
      //let attributes: MatchFilter = mapFilterAttributes(this.sport, this.filter); //until activate soccer
      let attributes: MatchFilter = {
        type: this.filter.type,
        size: this.filter.size,
        wall: this.filter.subType,
      };
      if(this.sport !== Sport.PADEL){
        attributes.type = 0;
        attributes.size = 0;
        attributes.wall = 0;
        if(this.sport === Sport.SOCCER){
          attributes.surface = 3;
          delete attributes.wall;
        }
      }
      var request: any = {
        start: this.date,
        duration: this.duration,
        sport: this.sport,
        origin: BookingHelper.getOrigin(),
        competitive: this.competitive,
        gender: this.gender,
        pricePerParticipant: Number(this.price.price),
        currencyCode: this.price.currency,
        name: this.locName,
        cordX: Number(this.cordX),
        cordY: Number(this.cordY),
        locale: LocationHelper.getLocation(),
        levelProvider: this.levelProvider,
        attributes: attributes
      };
      const response = await MatchHelper.createMatchWithoutSale(request);
      return this.$router.push(`/booking-join/${response.id}`);
    },
    handleLocationSelected(selection: any) {
      if (selection.location.length === 0) {
        return;
      }
      const date = new Date(selection.date);
      this.hour = formatTime(date, navigator);
      this.date = formatDate(setTimeToDate(date, this.hour))
      let location = null;
      if(Array.isArray(selection.location)){
        location = selection.location[0];
      }else{
        location = selection.location;
      }
      if(location && location.name){
        this.cordX = location.cordX;
        this.cordY = location.cordY;
        this.locName = location.name;
        this.$route.query
        this.updateUrl();
      }
    },
    updateUrl(){
      this.$router.replace({
        query: {
          ...this.$route.query,
          cordX: this.cordX,
          cordY: this.cordY,
          locName: this.locName
        },
      });
    },
    handleTenantSelected(selection: any) {
      this.locationTenantId = selection.tenant.id;
    },
    priceSelected(booking: selectedPrice) {
      window.scrollTo(0, document.body.scrollHeight);
      this.booking = booking;
      this.duration = this.booking!.duration
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector(".clueFormContainer");
        if (resultsContainer) {
          resultsContainer.classList.add('pb-100');
        }
      }
    }
  },
});
</script>
<style>
@media (max-width: 767px) {
  .wrapperClueW {
    width: 85%;
  }
}
.button-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
.wrapperClueW .search-container input {
  width: 100%;
}
.barLevelClue {
  background: rgb(9, 9, 121);
  background: linear-gradient(90deg, var(--color1) 35%, var(--color2) 100%);
  width: 50%;
  height: 100%;
}
.levelText {
  font-size: 8px;
}
@media (min-width: 767px) {
  .clueFormContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }
  .wrapperClueW {
    width: 60%;
  }
}
.altBackground {
  background: var(--background-color);
}
.br60 {
  border-radius: 100px;
}
.br-50 {
  border-radius: 50%;
}
.filterClue .selected {
  background-color: var(--color2);
}
.separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.144);
}
.clueColor {
  background-color: #1c2a41;
}
</style>