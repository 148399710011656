import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_CookiesPrivacy = _resolveComponent("CookiesPrivacy")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_GlobalError = _resolveComponent("GlobalError")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Login, { onRefresh: _ctx.refreshed }, null, 8, ["onRefresh"]),
    _createVNode(_component_CookiesPrivacy),
    _createVNode(_component_router_view),
    _createVNode(_component_GlobalError),
    _createVNode(_component_Loader)
  ]))
}