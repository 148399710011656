<template>
  <div>
    <VerifyPhone />
    <Header></Header>
    <Onboarding @refresh="handleRefresh" />
    <div class="home container text-center mx-auto">
      <div class="grid grid-cols-1 text-center">
        <h1
          class="sm:text-2xl sm:font-semibold sm:leading-8 sm:text-center sm:mt-5"
        >
          <span id="sport_text">
            {{ $t("do_you_play").toUpperCase() + " " }}
          </span>
          <span class="custom-gradient container_rotating-text rotating-text"
            >{{ currentWord.toUpperCase() }}
          </span>
        </h1>
        <div class="mt-4">
          <ActivityBar @optionSelected="handleAtivitySelected" />
        </div>
        <div
          v-if="!isLoading"
          class="mt-8 sm:mt-4 flex items-center justify-center"
        >
          <div class="sm:w-full w-3/5">
            <SportButtonSelector 
            :sport="activeSport"
            :key="activeSport" 
            @sportSelected="selectSport" />
          </div>
        </div>
        <div class="mt-4 sm:mt-4">
          <MainSearchForm
            @locationSelected="handleLocationSelected"
            @tenantSelected="handleTenantSelected"
            :key="mainSearchFormKey"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-4 sm:mt-0"></div>
    <footer class="flex flex-nowrap">
      <div
        v-if="!isIOWebView()"
        class="flex flex-nowrap"
        @click="showClubForm = true"
      >
        <div class="flex-auto text-left">
          <div class="view-courts">
            <a href="#page2"
              ><span class="text-sm">{{
                $t("are_you_a_club_question")
              }}</span></a
            >
            <SvgContainer name="homeFooterBackGroundLeft" />
            <div class="open">
              <a href="#page2">
                <SvgContainer name="homeViewIcon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-auto w-64 text-center"></div>
    </footer>
  </div>
  <Transition>
    <PopUpLight v-if="showClubForm" @closed="closeClubForm">
      <div class="mt-8 flex flex-col">
        <span class="mb-4 w-full">
          <InputText v-model="clubOwner" :placeholder="$t('write_your_name')" />
        </span>
        <InputText v-model="clubName" :placeholder="$t('write_club_name')" />
        <InputEmail v-model="clubEmail" />
        <InputPhone @phoneValidated="handlePhoneValidated" />
        <div
          @click="hasErrors ? null : clubInterested()"
          class="flex justify-center filledButton md:mt-8 sm:mt-4 cursor-pointer sm:w-full"
          :class="{ 'button-disabled': hasErrors }"
        >
          {{ $t("send_mail") }}
        </div>
        <h2 class="send-mail-message">{{ clubMsgSend }}</h2>
      </div>
    </PopUpLight>
  </Transition>
  <Transition>
      <PopUpLight v-if="!hasGender"
        :showClose="false"
      >
        <div class="mt-8">
          <UserGenderSelector @selectionChanged="saveGenderChanges" />
        </div>
      </PopUpLight>
    </Transition>
</template>
<script>
import { useStore } from "vuex";
import { defineComponent } from "vue";
import Onboarding from "./onboarding/onboarding.vue";
import Header from "@/components/header/header.vue";
import SvgContainer from "./SvgContainer.vue";
import SportButtonSelector from "@/components/form/sportButtonSelector.vue";
import InputText from "@/components/form/inputText.vue";
import InputEmail from "@/components/form/inputEmail.vue";
import InputPhone from "@/components/form/inputPhone.vue";
import MainSearchForm from "@/components/search/mainSearchForm.vue";
import { getSearchDataToSend, getDataToBooking, useRefreshWatcher } from "@/helpers/dataHelper";
import { Category, Sport, BookingType,  NO_LEVEL } from "@/enum/constants";
import { AppsHelper } from "@/helpers/appsHelper";
import PopUpLight from "@/components/popup/popupLight.vue";
import { Format } from "@/helpers/formatHelper";
import EmailHelper from "@/helpers/emailHelper";
import VerifyPhone from "@/components/verify/verifyPhone.vue";
import CustomerHelper from "@/helpers/customerHelper";
import ActivityBar from "@/components/booking/activityBar.vue";
import UserGenderSelector from "@/components/form/userGenderSelector.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    SvgContainer,
    Onboarding,
    Header,
    SportButtonSelector,
    MainSearchForm,
    PopUpLight,
    InputText,
    InputEmail,
    InputPhone,
    VerifyPhone,
    ActivityBar,
    UserGenderSelector
  },
  async mounted() {
    if (window.innerWidth > 768) {
      document.body.style.background =
        "url(/img/background-test.485cbfa3.png) top 100% center";
      document.body.style.backgroundRepeat = "no-repeat";
    }
    setInterval(() => {
      this.index = (this.index + 1) % this.words.length;
      this.currentWord = this.words[this.index];
    }, 2000); // change word every 2 seconds
    useRefreshWatcher(() => { this.handleRefresh(); });// This function will execute when the 'isRefreshed' state changes. It is passed this way because it is passed by reference.
  },
  data() {
    return {
      category: Category,
      isLoading: true,
      mainSearchFormKey: 0,
      showClubForm: false,
      clubOwner: "",
      clubName: "",
      clubPhone: "",
      clubEmail: "",
      clubPhonePrefix: "",
      clubMsgSend: "",
      sendEnabled: true,
      activeSport: Sport.PADEL,
      sport: Sport,
      words: [
        this.$t("padel_interrogation"),
        this.$t("tennis_interrogation"),
        this.$t("pickleball_interrogation"),
        this.$t("padbol_interrogation"),
        this.$t("racquetball_interrogation"),
        this.$t("squash_interrogation"),
      ],
      currentWord: this.$t("padel_interrogation"),
      index: 0,
      isUserLogged: false,
      hasGender: true,
      activitySelected: BookingType.BOOKING
    };
  },
  async created() {
    this.store = useStore();
    this.selectSport(Sport.PADEL);

    const userItem = localStorage.getItem("user");
    if (userItem !== null) {
      this.storePreferences();
    }
    this.isLoading = false;
  },
  computed: {
    hasErrors() {
      return (
        Format.IsNull(this.clubOwner) ||
        Format.IsNull(this.clubName) ||
        Format.IsNull(this.clubEmail) ||
        Format.IsNull(this.clubPhonePrefix) ||
        Format.IsNull(this.clubPhone) ||
        !this.sendEnabled
      );
    },
  },
  methods: {
    async saveGenderChanges(gender) {
      const user = await CustomerHelper.updateGender(gender);
      if (!Format.IsNull(user)) {
        CustomerHelper.updateStorageUser(user);
      }
      this.hasGender = true;
    },
    async handleRefresh() {
      this.isLoading = true;
      if (CustomerHelper.isUserLogged()) {
        CustomerHelper.getCustomer();
        if(this.activitySelected == BookingType.MATCH){
          this.hasGender = await CustomerHelper.hasGender();
        }
      }
      this.storePreferences();
      this.isLoading = false;
    },
    async handleAtivitySelected(activitySelected) {
      this.activitySelected = activitySelected;
      this.store.commit("setPreferencesAction", this.activitySelected);
      if(this.activitySelected == BookingType.MATCH){
        this.isUserLogged = CustomerHelper.isUserLogged();
        if(this.isUserLogged){
          //this.hasGender = await CustomerHelper.hasGender();
        }
      }
    },
    selectSport(sport) {
      this.activeSport = sport;
      this.store.commit("setPreferencesSport", sport);
    },
    handleLocationSelected(selection) {
      this.store.commit("setSearchIsFlexible", selection.flexible);
      this.store.commit("setfullDateTime", selection.date);
      const dataToSend = getSearchDataToSend();
      if (selection.location?.length > 0) {
        dataToSend.locName = selection.location[0].name;
      }

      // Aux funtion to convert undefined to null
      const convertUndefinedToNull = (value) => {
        return value !== undefined ? value : null;
      };
      // Mapping all values and convert undefined to null
      const filteredDataToSend = Object.fromEntries(
        Object.entries(dataToSend).map(([key, value]) => [
          key,
          convertUndefinedToNull(value),
        ])
      );
      this.$router.push({ path: "/search-results", query: filteredDataToSend });
    },
    handleTenantSelected(tenant) {
      this.store.commit("setSearchIsFlexible", tenant.flexible);
      this.store.commit("setfullDateTime", tenant.date);
      var loc = getDataToBooking(tenant.tenant);
      this.$router.push(loc);
    },
    storePreferences() {
      const userItem = localStorage.getItem("user");
      if (userItem != null) {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.preferences != null) {
          if (user.preferences.action != null) {
            this.store.commit("setPreferencesAction", user.preferences.action); //TODO: modificar el funcionamiento de actions ya que se ha complicado desde que se hizo funcionalidad tipo radiobutton
            this.activitySelected = user.preferences.action;
          }

          if (user.preferences.sport != null) {
            this.store.commit("setPreferencesSport", user.preferences.sport);
            this.activeSport = user.preferences.sport;
          }

          if (
            user.preferences.places !== null &&
            user.preferences.places.length !== 0
          ) {
            this.store.commit("setPreferencesPlaces", user.preferences.places);
          }

          if (
            user.preferences.tenants !== null &&
            user.preferences.tenants.length !== 0
          ) {
            this.store.commit(
              "setPreferencesTenants",
              user.preferences.tenants
            );
          }
        }
      }
      this.mainSearchFormKey++; //By changing the key value, Vue recognizes it as a new component mainSearchFormKey, and destroys the previous instance before creating a new one, to get preference data
    },
    clubInterested() {
      EmailHelper.sendEmail(
        this.clubName,
        this.clubOwner,
        this.clubEmail,
        this.clubPhonePrefix,
        this.clubPhone
      );
      this.clubMsgSend = this.$t("thank_you_for_contacting");
      this.sendEnabled = false; //spam control
    },
    isIOWebView() {
      return AppsHelper.isIOSWebView();
    },
    handlePhoneValidated(phone, prefix) {
      this.clubPhone = phone;
      this.clubPhonePrefix = prefix;
    },
    closeClubForm() {
      this.clubName = "";
      this.clubOwner = "";
      this.clubEmail = "";
      this.clubPhonePrefix = "";
      this.clubPhone = "";
      this.clubMsgSend = "";
      this.showClubForm = false;
      this.sendEnabled = true;
    },
  },
});
</script>

<style scoped>
@media (min-width: 767px) {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 46px;
    font-weight: 600;
    line-height: 67px;
    letter-spacing: 0.04em;
    text-align: center;
    margin-top: 20px;
  }
}
@media (min-width: 767px) {
  .tabs li.image {
    background: rgba(255, 255, 255, 0.02);
    opacity: 0.8;
    box-shadow: inset 0px 0px 15px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(20px);
    border-radius: 30px;
    width: 40px;
    height: 40px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

label {
  margin: 0px 0px 0px 8px;
}
.tabs li:first-child {
  margin-left: 0px;
}

.tabs li:hover {
  color: #ffffff;
}
.tabs li.image:hover svg path {
  fill: #999999;
}
footer {
  width: 90%;
  margin: 20px auto 0px auto;
  padding-bottom: 0px;
}
footer h3 {
  font-size: 46px;
  font-weight: 600;
}
footer h4 {
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  display: inline-flex;
  max-width: 140px;
}
footer .open svg {
  position: relative;
  top: -39px;
  left: 145px;
}
.view-courts span {
  position: relative;
  top: 56px;
  left: 7px;
  font: 18px;
  font-weight: 600;
}
.all-tracks {
  width: 184px;
  height: 114px;
  background: #0458e2;
  border-radius: 12px;
}
.all-tracks a {
  position: relative;
  top: 45px;
  left: 40px;
  font: 18px;
  font-weight: 600;
}
input[type="checkbox"] {
  appearance: none;
  background-color: transparent;
  border: 1px solid #f3f3f34d;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  outline: none;
}

input[type="checkbox"].selected {
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-radius: 100px;
}
input[type="checkbox"].selected::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 5px;
  top: 3px;
  width: 4px;
  height: 7px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}
.select-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14.5px 142px;
  width: 100%;
  height: 48px;
  background: linear-gradient(
    103.18deg,
    var(--color1) -38.71%,
    var(--color2) 57.77%
  );
  box-shadow: -2px 4px 18px rgba(0, 15, 53, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 32px;
  margin-top: 24px;
}
.search-image {
  background: linear-gradient(
    265.36deg,
    #0356e1 0.37%,
    #23edee 136.83%
  ) !important;
}
.wrapper-icon-home .container-custom-icon {
  border-radius: 50%;
}
.button-disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}
h2 {
  font-weight: 500;
  font-size: 32px;
  background: linear-gradient(
    103.18deg,
    var(--color1) -38.71%,
    var(--color2) 57.77%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-align: left;
  margin: 40px 0px 0px 16px;
}

.container_rotating-text {
  display: inline-block;
  position: relative;
}

.rotating-text,
.sport_text {
  animation: slide 2s infinite;
}

@keyframes slide {
  0% {
    transform: translateY(0px); /* Start 30 pixels above the original position */
    opacity: 1;
  }
  20% {
    transform: translateY(-10px); /* Bounce down to 10 pixels below the original position */
    opacity: 1;
  }
  40% {
    transform: translateY(5px); /* Bounce back up to 5 pixels above the original position */
    opacity: 1;
  }
  60% {
    transform: translateY(-2px); /* Bounce slightly down to 2 pixels below the original position */
    opacity: 1;
  }
  85% {
    transform: translateY(1px); /* Bounce slightly up to 1 pixel above the original position */
    opacity: 0;
  }
  100% {
    transform: translateY(0); /* Settle at the original position */
    opacity: 0;
  }
}
</style>