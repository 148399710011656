<template>
  <span v-if="booking !== null">
    <Header></Header>
    <div class="fixed top-16 left-[10%] z-[100]"><HistoryBack /></div>
    <div class="paymentWrapper">
      <div class="wrapperCheckout flex justify-around sm:flex-col">
        <BookingDetail
          :booking="booking"
          :showShare="false"
          :showOpenFacility="false"
          :showResourceName="true"
          class="paymentBoxLeft p-6 pt-8 md:mr-6"
        ></BookingDetail>
        <div class="paymentBoxRight md:p-6 sm:pt-4 md:mr-6 sm:mb-6 relative">
          <h2 class="sm:hidden">{{ $t("pay_mode").toUpperCase() }}</h2>
          <div class="backgroundPayment md:mt-8 sm:pb-7">
            <div
              v-if="showShared"
              :class="{ customBorder: fullPayment === false }"
              class="paymentOption cursor-pointer flex items-center flex-row p-6 justify-between sm:flex-col sm:justify sm:relative"
              v-on:click="setFullPayment(false)"
            >
              <div class="flex items-center">
                <div
                  v-if="!isMatch"
                  class="check paySelected mr-6 relative sm:mt-6"
                ></div>
                <div class="w-44">
                  <h2 v-if="!isMatch" class="mb-2 text-xl sm:text-sm">
                    {{ $t("shared_payment") }}
                  </h2>
                  <h2 v-else class="mb-2 text-xl sm:text-sm">
                    {{ $t("payment") }}
                  </h2>
                </div>
              </div>

              <div class="sm:flex mr-4">
                <div class="multiplier sm:text-xs sm:mr-2 sm:ml-10">
                  X{{ getTotalPlayersSelected() }}
                </div>
                <p class="text-lg sm:text-lg">{{ getSharedStrPrice() }}</p>
              </div>
              <div
                class="customBorder addPlayers sm:p-1 p-3 flex justify-center sm:absolute overflow-hidden break-words items-center"
              >
                <div @click="goToAddPlayers()">
                  <div class="playerIcon pb-4 relative"></div>

                  <p
                    v-if="!playersMode && !isMatch"
                    class="text-sm text-center sm:text-xs"
                  >
                    {{ $t("manage_players") }}
                  </p>
                  <p
                    v-if="!playersMode && isMatch"
                    class="text-sm text-center sm:text-xs"
                  >
                    {{ $t("edit_positions") }}
                  </p>
                  <p v-if="playersMode" class="text-sm text-center sm:text-xs">
                    {{ $t("edit_positions") }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="playersMode" class="containerPayPlayers">
              <div
                v-if="getOwner() !== undefined"
                class="checkoutPlayers w-full mt-4 p-2"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <!-- <div class="firstCheck ml-4 md:ml-6 mr-6 relative"></div> -->
                    <div class="flex items-center sm:justify-center">
                      <img
                        class="sm:w-8 sm:h-8 mr-3 md:w-12"
                        src="../assets/images/genericPlayer.png"
                        alt=""
                      />
                      <div class="mr-2">
                        <p class="playerSubtitle sm:text-xs">
                          {{ getOwner().name }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="mr-4">{{ getPrice(getOwner().customerId) }}</p>
                </div>
              </div>
              <div
                v-for="(player, index) in booking.players.filter(
                  (y) =>
                    y.owner === false &&
                    (!y.hasOwnProperty('paid') || y.paid === false)
                )"
                :key="index"
                class="checkoutPlayers w-full mt-4 p-2"
              >
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <!-- <div class="check ml-2 mr-3 relative"></div> -->
                    <div class="flex items-center sm:justify-center">
                      <img
                        class="sm:w-8 sm:h-8 mr-3 md:w-12"
                        src="../assets/images/genericPlayer.png"
                        alt=""
                      />
                      <div class="mr-2">
                        <p class="playerSubtitle sm:text-xs">
                          {{ player.name ? player.name : "Guest Player" }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class="mr-4">{{ getPrice(player.customerId) }}</p>
                </div>
              </div>
            </div>
            <div
              v-if="showFull"
              :class="{ customBorder: fullPayment === true }"
              v-on:click="setFullPayment(true)"
              class="paymentOption cursor-pointer flex md:items-center md:flex-row p-6 justify-between md:mt-8 sm:mt-4 sm:flex-col"
            >
              <div class="flex items-center">
                <div class="check paySelected mr-6 relative sm:mt-6"></div>
                <div class="w-44">
                  <h2 class="mb-2 text-xl sm:text-sm">
                    {{ $t("full_payment") }}
                  </h2>
                  <p class="text-sm playerSubtitle pr-2 sm:text-xs sm:mb-2">
                    <span class="text-white">
                      {{ $t("you_assume_full_payment") }}</span
                    >
                  </p>
                </div>
              </div>
              <div class="sm:flex sm:ml-10">
                <p class="text-lg sm:text-lg">{{ getFullStrPrice() }}</p>
              </div>
            </div>
            <div
              v-if="showInHOuse"
              :class="{ customBorder: fullPayment === true }"
              v-on:click="setFullPayment(true)"
              class="paymentOption cursor-pointer flex md:items-center md:flex-row p-6 justify-between md:mt-8 sm:mt-4 sm:flex-col"
            >
              <div class="flex items-center">
                <div class="check paySelected mr-6 relative sm:mt-6"></div>
                <div>
                  <h2 class="mb-2 text-xl sm:text-sm">
                    {{ $t("pay_in") }}
                    {{ capitalizeFirstLetter(booking.tenant) }}
                  </h2>
                </div>
              </div>
            </div>
            <div
              v-if="!playersMode && !payInHouse"
              class="bottomTotal flex justify-between pb-4 items-center md:mt-20 sm:mt-4 p-4"
            >
              <div>
                <h2 class="text-xl sm:text-sm">{{ $t("total_amount") }}</h2>
              </div>
              <div>
                <p class="text-2xl sm:text-lg">
                  {{
                    fullPayment === false
                      ? getSharedStrPrice()
                      : getFullStrPrice()
                  }}
                </p>
              </div>
            </div>
            <div
              class="flex justify-center buttomPayMode md:mt-8 sm:mt-4 mb-8 cursor-pointer"
              @click="procedToPay()"
            >
              {{ $t("checkout") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/header/header.vue";
import BookingDetail from "../components/booking/bookingDetail.vue";
import { Format } from "@/helpers/formatHelper";
import { BookingType, PadelSize, PaymentType } from "@/enum/constants";
import store from "../store";
import { BookingApi } from "@/service/BookingApi";
import { Player } from "@/custom-models/player";
import { customGlobalError } from "@/helpers/errorHelper";
import { Booking } from "@/custom-models/booking";
import { Price } from "@/custom-models/price";
import LocalStorage from "@/helpers/localStorage";
import { BookingHelper, returnSportPlayers } from "@/helpers/bookingHelper";
import { AppsHelper } from "@/helpers/appsHelper";
import MatchHelper from "@/helpers/matchtHelper";
import HistoryBack from "@/components/form/historyBack.vue";
import { capitalizeFirstLetter } from "@/helpers/dateHelper";
import { encodeString, decodeString, useRefreshWatcher } from "@/helpers/dataHelper";
import { CheckoutPayment } from "@/custom-models/checkoutPayment";
import { PricesRequest } from "models";
import CustomerHelper from "@/helpers/customerHelper";
import { MatchFilter } from "@/custom-models/matchFilter";

export default defineComponent({
  name: "checkoutView",
  components: {
    Header,
    BookingDetail,
    HistoryBack,
  },
  data() {
    return {
      booking: {} as Booking,
      fullPayment: true,
      playersMode: false,
      prices: [] as Price[],
      urlRedirect: null,
      fromJoin: false,
      cancellationLimitDate: "",
      isMatch: false,
      payInHouse: false as boolean,
      payShared: false as boolean,
      payFull: false as boolean,
      showShared: false as boolean,
      showFull: false as boolean,
      showInHOuse: false as boolean,
      fullPaymentPrice: null as number | null,
      priceStr: "",
      size: null as any | null
    };
  },
  async created() {
    const query = this.$route.query.pl;
    if (query === undefined || query === null || query === "") {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }

    let utf8Query = null;
    try {
      utf8Query = decodeString(query.toString());
    } catch (error) {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }

    try {
      this.booking = JSON.parse(utf8Query);
    } catch (error) {
      customGlobalError(this.$t("wrong_url"), "/");
      return;
    }
    if (this.booking === null) {
      return;
    }

    this.size = this.booking.size;
    if (this.size !== undefined && this.size.code !== undefined) {
      this.size = this.size.code;
    }
    if(!this.size ){
      if(this.booking.attributes){
        this.size = this.booking.attributes.size
      }
    }
    if (
      (this.booking.type !== undefined &&
        this.booking.type.code === BookingType.MATCH) ||
      this.booking.matchRequest
    ) {
      this.isMatch = true;
      this.fullPayment = false;
      // Add customer as first player to get personalized price
      const user = localStorage.getItem("user");
      if (user === null || user === "") {
        store.commit("openLogin");
        return;
      }
      if (this.booking.players === undefined) {
        this.booking.players = [];
        this.booking.players.push({
          customerId: JSON.parse(user).id,
          owner: true,
          paid: false,
        });
      }
    }

    if (this.booking.id !== undefined && this.booking.id !== null) {
      this.fromJoin = true;
    }
    if (this.booking.players !== undefined) {
      if (!this.isMatch) {
        this.playersMode = true;
        this.fullPayment = false;
        const owner = this.getOwner();
        if (owner !== undefined) {
          owner.selected = true;
        }
      }
      await this.loadPrices();
      return;
    }
    this.booking.players = [];
    this.refreshPrices();
  },
  mounted() {
    useRefreshWatcher(() => { this.refreshPrices(); });
    if (window.location.href.includes("checkout")) {
      document.body.style.overflowY = "scroll";
    }
    document.body.style.background = "none"; // todo hacerlo bien con layouts
    this.fixIphoneHeight();
    if (this.booking.allowedPaymentMethods) {
      this.payInHouse = this.booking.allowedPaymentMethods.includes(
        PaymentType.IN_HOUSE
      );
      this.showInHOuse = this.payInHouse && !this.playersMode;
      if (this.showInHOuse) {
        return;
      }

      this.payShared = this.booking.allowedPaymentMethods.includes(
        PaymentType.SHARED
      );
      this.showShared = !this.payInHouse && this.payShared;

      this.payFull = this.booking.allowedPaymentMethods.includes(
        PaymentType.FULL
      );
      this.showFull = !this.payInHouse && this.payFull && !this.playersMode && !this.isMatch;
      if(!this.showFull){
        this.setFullPayment(false);
      }
    }
  },
  methods: {
    capitalizeFirstLetter,
    async refreshPrices() {
      const user = CustomerHelper.getUser();
      if (user) {
        const userPrice = {
          customerId: user.id,
          name: user.fullName,
          position: 0,
          owner: true,
          paid: false,
          image: user.image,
          selected: true,
        };
        this.booking.players!.push(userPrice);
        await this.getPrices();
      }
    },
    getFullStrPrice() {
      let fullAmount = Number(this.booking.price.grossAmount);
      if (this.fullPaymentPrice) {
        fullAmount = this.fullPaymentPrice;
      }
      return Format.formatCurrency(
        fullAmount,
        this.booking.price.currencyCode,
        this.booking.price.locale
      );
    },
    getSharedStrPrice() {
      if(this.isMatch){
        return this.priceStr;
      }
      if (this.playersMode) {
        return this.getPlayersTotalAmount();
      }
      if (this.booking.price === undefined) {
        return;
      }
      const numPlayers = returnSportPlayers(Number(this.size));
      let amount = Number(this.fullPaymentPrice);
      if (!this.isMatch) {
        amount = amount / numPlayers;
      }
      return Format.formatCurrency(
        amount,
        this.booking.price.currencyCode,
        this.booking.price.locale
      );
    },
    getPriceRequest(): PricesRequest {
      const priceType = this.isMatch ? BookingType.MATCH : BookingType.BOOKING;
      const numPlayers = returnSportPlayers(Number(this.size));
      let idArray = this.booking.players !== undefined
            ? this.booking.players
                .filter((y) => y.paid === false)
                .map((y: any) => y.customerId)
            : []; // Array inicial con IDs
      const remainingPlayers = numPlayers - idArray.length;

      if (remainingPlayers > 0) {
        for (let i = 0; i < remainingPlayers; i++) {
          idArray.push("");
        }
      }
      
      return {
        resourceId: this.booking.resourceId as number,
        start: new Date(this.booking.date),
        duration: this.booking.duration,
        sport: this.booking.sport,
        customerIds:idArray,
        tenantId: this.booking.tenantId,
        type: priceType,
      };
    },
    async getPrices() {
      if (this.booking === null) {
        return;
      }
      const pricesRequest = this.getPriceRequest();
      const response = await BookingHelper.getPrices(pricesRequest);
      if (response) {
        this.fullPaymentPrice = Number(response.totalAmount.grossAmount);
        return;
      }
      customGlobalError(
        this.$t("oops_technical_problems_try_again_in_a_few_minutes")
      );
    },
    async loadPrices() {
      if (this.booking === null) {
        return;
      }
      const pricesRequest = this.getPriceRequest();
      const response = await BookingHelper.getPrices(pricesRequest);
      if (response) {
        this.prices = response.players;
        this.priceStr = response.totalAmount.grossAmountStr;
        return;
      }
      customGlobalError(
        this.$t("oops_technical_problems_try_again_in_a_few_minutes")
      );
    },
    goToAddPlayers() {
      const user = localStorage.getItem("user");
      if (user === null || user === "") {
        store.commit("openLogin");
        return;
      }

      this.$router.push(this.getUrlPlayers());
    },
    getUrlPlayers() {
      if (this.fromJoin) {
        return `/booking-join/${this.booking.id}`;
      }
      return `/players?pl=${this.$route.query.pl}`;
    },
    getOwner() {
      const players: any = this.booking.players;
      return players.find((y: any) => y.owner);
    },
    getPrice(id: string | null) {
      if (this.prices.length === 0) {
        return;
      }
      if (id === "") {
        id = null; // this.prices not assigned customers comes with id null
      }
      const price: any = this.prices.find(
        (y: any) => y.customerId === id || y.customerId === null
      ); //add null because a player can't be in takus

      if (price === undefined || price === null) {
        customGlobalError(
          this.$t("oops_technical_problems_try_again_in_a_few_minutes")
        );
        return;
      }
      return price.price.grossAmountStr;
    },
    selectPlayer(player: Player) {
      if (player.selected !== true) {
        player.selected = true;
        return;
      }

      player.selected = false;
    },
    getPlayersTotalAmount() {
      if (this.booking === null) {
        return;
      }
      if (this.prices.length === 0) {
        return;
      }
      let total = 0.0;
      const data: any = this.booking;
      if (this.fromJoin) {
        for (const price of this.prices) {
          if (price.price !== undefined) {
            total += price.price.grossAmount;
          }
        }
      } else {
        for (const player of data.players) {
          if (player.selected) {
            const price: any = this.prices.find(
              (y: any) => y.customerId === player.customerId
            );
            if (price !== undefined) {
              total += price.price.grossAmount;
            }
          }
        }
      }

      const anyPrice: any = this.prices[0];
      return Format.formatCurrency(
        Number(total.toString()),
        anyPrice.price.currencyCode,
        anyPrice.price.locale
      );
    },
    async procedToPay() {
      const user = localStorage.getItem("user");
      let response = null;
      if (user === null || user === "") {
        store.commit("openLogin");
        return;
      }

      if (this.fromJoin) {
        let players: any = {};
        if (this.booking.players !== undefined) {
          players = this.booking.players
            .filter((y: any) => y.paid === false && y.playerId !== undefined)
            .map((y: any) => y.playerId);
        }
        const response = await BookingApi.createSale(
          this.booking.id as string,
          players
        );
        switch (response.status) {
          case 201:
            this.goToPay(
              response.data.saleId,
              response.data.bookingId,
              response.data.cancellationLimit
            );
            break;
          case 409:
            customGlobalError(this.$t("purchase_process_expired"));
            break;
        }
        return;
      }
      var request: any = {
        resourceId: this.booking.resourceId as number,
        start: this.booking.date,
        duration: this.booking.duration,
        sport: this.booking.sport,
        players: this.configurePlayers(),
        tenantId: this.booking.tenantId,
        origin: BookingHelper.getOrigin(),
        paymentType: this.fullPayment ? PaymentType.FULL : PaymentType.SHARED,
        type: this.booking.type,
      };

      if (this.payInHouse) {
        request.paymentType = PaymentType.IN_HOUSE;
      }
      store.commit("setLoading", true);
      if (this.isMatch) {
        let matchRequest = this.booking.matchRequest;
        (matchRequest.attributes = this.booking.attributes as MatchFilter),
          (matchRequest.players = this.configurePlayers());
        response = await MatchHelper.createMatch(matchRequest);
      } else {
        response = await BookingApi.prebooking(request);
      }

      store.commit("setLoading", false);

      let id = response.data.bookingId;
      if (response.data.free === true) {
        this.booking.id = id;
        LocalStorage.addWithTimout(id as string, this.booking);
        return this.$router.push("/checkout-ok/" + id);
      }

      switch (response.status) {
        case 201:
          this.goToPay(
            response.data.saleId,
            id,
            response.data.cancellationLimit
          );
          break;
        case 409:
          customGlobalError(this.$t("purchase_process_expired"));
          break;
      }
    },
    goToPay(saleId: string, bookingId: string, cancellationLimit: string) {
      this.booking.id = bookingId;
      LocalStorage.addWithTimout(saleId, this.booking);
      const paymentJson: CheckoutPayment = {
        saleId: saleId,
        saleType: this.isMatch ? BookingType.MATCH : BookingType.BOOKING,
      };
      if (cancellationLimit !== null) {
        paymentJson.cancellationDate = cancellationLimit;
      }
      return this.$router.push({
        name: "checkoutPaymentViewCoded",
        params: { data: encodeString(JSON.stringify(paymentJson)) },
      });
    },
    configurePlayers() {
      if (this.fullPayment) {
        this.booking.players = [];
        return this.booking.players;
      }
      // shared payments, not players assigned
      if (
        this.booking.players === undefined ||
        this.booking.players === null ||
        this.booking.players.length === 0
      ) {
        const user = localStorage.getItem("user");
        if (user === null || user === "") {
          store.commit("openLogin");
          return;
        }

        if (this.booking.players === undefined) {
          return;
        }

        this.booking.players.push({
          customerId: JSON.parse(user).id,
          owner: true,
        });
      }
      // shared payment
      for (var i = 0; i < this.booking.players.length; i++) {
        let player = this.booking.players[i];
        player.addToSale = false;
        if (player.selected || player.owner) {
          player.addToSale = true;
        }

        if (i == 0 || i == 2) {
          player.team = 0;
        } else {
          player.team = 1;
        }

        if (i == 0 || i == 1) {
          player.side = 0;
        } else {
          player.side = 1;
        }
      }
      return this.booking.players;
    },
    getTotalPlayersSelected() {
      let count = 0;
      if (this.booking.players === undefined) {
        return 1;
      }
      if (this.fromJoin) {
        count = this.booking.players.length;
      } else {
        count = this.booking.players.filter((y: any) => y.selected).length;
      }
      return count === 0 ? 1 : count;
    },
    setFullPayment(option: boolean) {
      this.fullPayment = option;
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector(".wrapperCheckout");
        if (resultsContainer) {
          resultsContainer.classList.add("pb-100");
        }
      }
    },
  },
});
</script>
<style scoped>
.paymentWrapper {
  width: 90%;
  margin: 0 auto;
}

.paymentBoxLeft {
  border-radius: 24px;
  background: var(--background-color-box);
  /* box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
}

.paymentBoxRight {
  border-radius: 24px;
}

.paymentBoxLeft .custom-gradient {
  border-bottom: 1px solid #ffffff21;
}

@media (min-width: 1100px) and (max-width: 1300px) {
  .paymentBoxRight {
    min-width: 50% !important;
  }

  .paymentBoxLeft {
    min-width: 50% !important;
  }
}

@media (min-width: 767px) {
  .addPlayers {
    width: 108px;
  }

  .paymentBoxLeft {
    width: 50%;
    min-width: 612px;
    /* height: 754px; */
  }

  .paymentBoxRight {
    width: 50%;
    min-width: 612px;
    height: 754px;
    background: var(--background-color-box);
    /* box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15); */
  }

  .multiplier {
    margin: 0 auto 14px auto;
  }
}

.multiplier {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--color-text-medium, #1c2a41);
  width: 39px;
}

.paymentOption {
  border-radius: 16px;
  border: 1px solid #1c2a41;
}

@media (max-width: 767px) {
  .paymentOption {
    background-color: var(--background-color-box);
    box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
    align-items: start;
  }

  .bottomTotal {
    background-color: var(--background-color-box);
    box-shadow: inset 0px 0px 12px rgba(255, 255, 255, 0.15);
  }

  .bottomTotal {
    border: 1px solid rgba(255, 255, 255, 0.192);
    border-radius: 16px;
  }
}

.bottomTotal {
  border-bottom: 1px solid rgba(255, 255, 255, 0.192);
  bottom: 0;
}

.customBorder {
  background: linear-gradient(
        var(--background-color-button),
        var(--background-color-button)
      )
      padding-box,
    linear-gradient(60deg, var(--color1), var(--color2)) border-box;
  border: 1px solid transparent;
}

.addPlayers {
  border: 2px solid transparent;
  border-radius: 8px;
  height: 128px;
}

.check {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #586577;
}

.customBorder .paySelected::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-image: url("../assets/images/plCheck.svg");
  background-size: 100%;
}

@media (max-width: 767px) {
  .addPlayers {
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    width: 80px;
    height: 95px;
  }

  .paymentOption .check {
    width: 20px;
    height: 20px;
  }

  .paySelected::after {
    width: 20px !important;
    height: 20px !important;
  }
}

.playerIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
  margin: 0 auto;
}

.playerIcon::after {
  content: "";
  position: absolute;
  height: 24px;
  width: 24px;
  background-size: 100%;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../assets/images/userw.svg");
}

@media (max-width: 767px) {
  .playerIcon {
    width: 32px;
    height: 32px;
  }

  .playerIcon::after {
    height: 16px;
    width: 16px;
  }
}

.buttomPayMode {
  border-radius: 32px;
  padding: 14px 0;
  background-image: linear-gradient(to right, var(--color1), var(--color2));
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 767px) {
  .buttomPayMode {
    border-radius: 32px;
    width: 327px;
    padding: 14px 0;
    background-image: linear-gradient(to right, var(--color1), var(--color2));
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767px) {
  .check {
    height: 20px !important;
    width: 20px !important;
  }
}

@media (max-width: 1100px) and (min-width: 767px) {
  .wrapperCheckout {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

.playerSelected {
  background: linear-gradient(
        var(--background-color-box),
        var(--background-color-box)
      )
      padding-box,
    linear-gradient(60deg, var(--color1), var(--color2)) border-box;
  border: 1px solid transparent;
}

.playerSelected .check::after {
  content: "";
  background-image: url("../assets/images/plCheck.svg");
  height: 25px;
  width: 25px;
  left: 50%;
  top: 50%;
  position: absolute;
  background-size: 100%;
  transform: translate(-50%, -50%);
}
</style>