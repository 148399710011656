<template>
  <div :class="cssClass">
    <div>{{ $t("court") }}</div>
    <div class="text-xs mt-6 mb-4">{{ $t("type") }}</div>
    <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
      <div
        @click="clickType($event, padelType.INDOOR)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: filter.type === padelType.INDOOR }"
      >
      {{ $t("indoor") }}
      </div>
      <div
        @click="clickType($event, padelType.OUTDOOR)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: filter.type === padelType.OUTDOOR }"
      >{{ $t("outdoor") }}
      </div>
      <div v-if="sportMode != sport.SOCCER"
        @click="clickType($event, padelType.COVER)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: filter.type === padelType.COVER }"
      >
      {{ $t("covered") }}
      </div>
    </div>
    <div class="mt-4 mb-4 separator"></div>

    <span v-if="sportMode === sport.PADEL">
      <div class="text-xs mt-6 mb-4">{{ $t("features") }}</div>
      <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
        <div
          @click="clickSubType($event, padelSubType.WALL)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === padelSubType.WALL }"
        >
        {{ $t("wall") }}
        </div>
        <div
          @click="clickSubType($event, padelSubType.CRISTAL)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === padelSubType.CRISTAL }"
        >
        {{ $t("crystal") }}
        </div>
        <div
          @click="clickSubType($event, padelSubType.PANORAMIC)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === padelSubType.PANORAMIC }"
        >
        {{ $t("panoramic") }}
        </div>
      </div>
    </span>

    <span v-if="sportMode === sport.TENIS">
      <div class="text-xs mt-6 mb-4">{{ $t("features") }}</div>
      <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
        <div
          @click="clickSubType($event, tenisSubType.CLAY)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === tenisSubType.CLAY }"
        >
        {{ $t("clay") }}
        </div>
        <div
          @click="clickSubType($event, tenisSubType.CEMENT)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === tenisSubType.CEMENT }"
        >
        {{ $t("cement") }}
        </div>
        <div
          @click="clickSubType($event, tenisSubType.SYNTHETICGRASS)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === tenisSubType.SYNTHETICGRASS }"
        >
        {{ $t("synthetic_grass") }}
        </div>
        <div
          @click="clickSubType($event, tenisSubType.GRASS)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === tenisSubType.GRASS }"
        >
        {{ $t("grass") }}
        </div>
      </div>
    </span>

    <span v-if="sportMode === sport.PICKLEBALL">
      <div class="text-xs mt-6 mb-4">{{ $t("features") }}</div>
      <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
        <div
          @click="clickSubType($event, pickleballSubType.ASPHALT)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === pickleballSubType.ASPHALT }"
        >
        {{ $t("asphalt") }}
        </div>
        <div
          @click="clickSubType($event, pickleballSubType.PLASTICPRESURE)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === pickleballSubType.PLASTICPRESURE }"
        >
        {{ $t("plastic_pressure") }}
        </div>
        <div
          @click="clickSubType($event, pickleballSubType.PARQUET)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === pickleballSubType.PARQUET }"
        >
        {{ $t("parquet") }}
        </div>
      </div>
    </span>

    <span v-if="sportMode === sport.SOCCER">
      <div class="text-xs mt-6 mb-4">{{ $t("features") }}</div>
      <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
        <div
          @click="clickSubType($event, pickleballSubType.ASPHALT)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === pickleballSubType.ASPHALT }"
        >
        {{ $t("asphalt") }}
        </div>
        <div
          @click="clickSubType($event, pickleballSubType.PLASTICPRESURE)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === pickleballSubType.PLASTICPRESURE }"
        >
        {{ $t("plastic_pressure") }}
        </div>
        <div
          @click="clickSubType($event, pickleballSubType.PARQUET)"
          class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
          :class="{ selected: filter.subType === pickleballSubType.PARQUET }"
        >
        {{ $t("parquet") }}
        </div>
      </div>
    </span>

    <div class="mt-4 mb-4 separator"></div>
    <div class="text-xs mt-6 mb-4">Size</div>
    <div class="flex flex-wrap justify-between mt-6 cursor-pointer">
      <div
        @click="clickSize($event, padelSize.DOUBLE)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: filter.size === padelSize.DOUBLE }"
      >
      {{ $t("common_double") }}
      </div>
      <div
        @click="clickSize($event, padelSize.INDIVIDUAL)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: filter.size === padelSize.INDIVIDUAL }"
      >
        {{ $t("individual") }}
      </div>

      <div
        v-if="sportMode === sport.TENIS || sportMode === sport.PICKLEBALL"
        @click="clickSize($event, tenisSize.MINI)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60"
        :class="{ selected: filter.size === tenisSize.MINI }"
      >
      {{ $t("mini") }}
      </div>
    </div>
    <Transition>
      <PopUpLight v-if="showRedpadelWarn" 
        @closed="showRedpadelWarn= false"
        :customClass="'width-lg'"
      >
        <div class="mt-8">
          {{ $t("redpadel_does_not_allow_individual_competitive_matches") }}
        </div>
      </PopUpLight>
    </Transition>
  </div>
</template>

<script lang="ts">
import {
  PadelSize,
  PadelType,
  PadelSubType,
  TenisSubType,
  TenisSize,
  PickleballSize,
  PickleballSubType,
  Sport,
  SoccerSubtype,
  SoccerSize,
  LevelProvider,
} from "@/enum/constants";
import PopUpLight from "@/components/popup/popupLight.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "filterSelector",
  components: {
    PopUpLight
  },
  props: {
    modelValue: {} as any,
    cssClass: String,
    sportMode: Number,
    levelProvider: Number,
    competitive: Boolean
  },
  watch: {
    sportMode() {
      this.setDefaultValues();
    },
    levelProvider(){
      this.valuesOk(this.filter.size);
    },
    competitive(){
      this.valuesOk(this.filter.size);
    }
  },
  data() {
    return {
      filter: { type: -1, subType: -1, size: -1 },
      sport: Sport,
      padelType: PadelType,
      padelSubType: PadelSubType,
      padelSize: PadelSize,
      tenisSubType: TenisSubType,
      tenisSize: TenisSize,
      pickleballSubType: PickleballSubType,
      pickleballSize: PickleballSize,
      soccerSubtype: SoccerSubtype,
      soccerSize: SoccerSize,
      showRedpadelWarn: false
    };
  },
  mounted() {
    if (this.modelValue !== undefined && this.modelValue !== null) {
      this.filter.type = this.modelValue.type;
      this.filter.subType = this.modelValue.subType;
      this.filter.size = this.modelValue.size;
    }
  },
  methods: {
    valuesOk(sizeType: any){
      if(this.sportMode == Sport.PADEL && this.levelProvider == LevelProvider.REDPADEL && this.competitive){
        if(sizeType === this.padelSize.INDIVIDUAL){
          this.filter.size = this.padelSize.DOUBLE;
          this.setUpFilters();
        }
        return false;
      }
      return true
    },
    setDefaultValues(){
      if(this.sportMode === Sport.PADEL){
        this.filter.size = this.padelSize.DOUBLE;
      }
    },
    clickType(e: any, type: any) {
      this.filter.type = type;
      this.setUpFilters();
    },
    clickSubType(e: any, type: any) {
      this.filter.subType = type;
      this.setUpFilters();
    },
    clickSize(e: any, type: any) {
      /*
      Si es padel + redpadel + competitivo no se puede crear individual
      Solo sale el proveedor si es redpadel
      */
      //0 1 false no es pot donar
      if(!this.valuesOk(type)){
        return this.showRedpadelWarn = true;
      }
      this.filter.size = type;
      this.setUpFilters();
    },
    setUpFilters() {
      this.$emit("update:modelValue", this.filter);
    },
  },
});
</script>

<style>
</style>
