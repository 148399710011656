<template>
  <div class="mt-8"></div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { decodeString } from "@/helpers/dataHelper";


export default defineComponent({
  name: "RedPadelRedirect",
  data() {
    return {
      payload: "" as string,
    };
  },
  components: {},
  mounted() {
    if(this.$route.query){
      const query = this.$route.query;
      window.parent.postMessage(query, process.env.VUE_APP_URL);
      return;
    }
  },
});
</script>