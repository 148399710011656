<template>
  <div class="fixed top-16 left-[10%] z-[100]">
    <HistoryBack/>
  </div>
  <div class="profileContainer text-sm mt-8">
    <div>
      <div class="topProfile flex justify-center items-center flex-col">
        <ProfileImage />
        {{ email }}
        <ProfileName />
        <ProfileNickName />
        <ProfileGender />

        <div class="w-64">
          <SportSelector v-model="sport" />
        </div>
      </div>
      <div class="bodyProfile p-6 pt-12">
        <div class="text-center mt-2 mb-2 font-bold">
          {{ $t("level") }}
        </div>
        <div
          class="progress-container flex flex-row sm:flex-col"
          :class="{ 'single-bar': !showRedpadelBar }"
        >
          <div v-if="mounted" class="progress-bar-wrapper altBackground p-4 pb-6 pt-6 items-center br16 w-full">
            <p v-if="mounted" class="mb-4 font-bold">
              {{ $t("taykus") + " " + results.level }}
            </p>
            <ProgressBar
              :firstValue="MIN_LEVEL"
              :lastValue="MAX_LEVEL"
              :value="results.level"
            />
          </div>
          <div v-if="showRedpadelBar && mounted" class="progress-bar-wrapper altBackground p-4 pb-6 pt-6 w-full items-center br16">
            <p v-if="mounted" class="mb-4 font-bold">
              {{ $t("redpadel") + " " + results.level }}
            </p>
            <ProgressBar
              :firstValue="MIN_LEVEL"
              :lastValue="MAX_REDPADEL_LEVEL"
              :value="results.level"
              :showAllLevels="false"
              :showMidLevel="true"
            />
          </div>
        </div>
        <WinRate v-if="mounted" :results="results" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ProfileImage from "@/components/myProfile/profileImage.vue";
import ProfileName from "@/components/myProfile/profileName.vue";
import ProfileNickName from "@/components/myProfile/profileNickName.vue";
import SportSelector from "@/components/form/sportSelector.vue";
import { Sport, MIN_LEVEL, MAX_LEVEL, MAX_REDPADEL_LEVEL } from "@/enum/constants";
import ProgressBar from "@/components/form/progressBar.vue";
import WinRate from "@/components/myProfile/winRate.vue";
import CustomerHelper from "@/helpers/customerHelper";
import HistoryBack from "@/components/form/historyBack.vue";
import store from "@/store";
import { AppsHelper } from "@/helpers/appsHelper";
import ProfileGender from "@/components/myProfile/profileGender.vue";

export default defineComponent({
  name: "UserProfile",
  components: {
    ProfileImage,
    ProfileName,
    ProfileNickName,
    SportSelector,
    ProgressBar,
    WinRate,
    HistoryBack,
    ProfileGender,
  },
  async mounted() {
    if (window.innerWidth > 768) {
      document.body.style.background =
        "url(/img/background-test.485cbfa3.png) top 100% center";
      document.body.style.backgroundRepeat = "no-repeat";
    }

    this.getResults();
    this.fixIphoneHeight();
    if (CustomerHelper.isUserLogged()) {
      this.customerId = await CustomerHelper.getCustomer().id;
      const user = await CustomerHelper.getUser();
      this.email = user.username;
    }
  },
  watch: {
    sport() {
      this.results = this.sportsArray.find(
        (sport) => sport.sport.code === this.sport
      );
      if (this.results.level < MIN_LEVEL) {
        this.results.level = MIN_LEVEL;
      }
    },
  },
  data() {
    return {
      results: null,
      sport: Sport.PADEL,
      sportsArray: [],
      mounted: false,
      customerId: "",
      email: "",
      MIN_LEVEL:MIN_LEVEL,
      MAX_LEVEL:MAX_LEVEL,
      showRedpadelBar: true,
      MAX_REDPADEL_LEVEL:MAX_REDPADEL_LEVEL

    };
  },
  methods: {
    async getResults() {
      this.sportsArray = await CustomerHelper.getCustomerResults();
      this.results = this.sportsArray.find(
        (sport) => sport.sport.code === this.sport
      );
      this.mounted = true;
    },
    fixIphoneHeight() {
      if (AppsHelper.isSafariOrIosWebView()) {
        const resultsContainer = document.querySelector(".profileContainer ");
        if (resultsContainer) {
          resultsContainer.classList.add("pb-100");
        }
      }
    },
  },
});
</script>

<style scoped>
.progress-container {
  display: flex;
  gap: 1rem;
}

.progress-bar-wrapper {
  flex: 1; /* progress bars share space */
}

.single-bar .progress-bar-wrapper {
  flex-basis: 100%; /*only 1 bar 100% of width */
}
@media (min-width: 767px) {
  .profileContainer {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
  }
}
.profileContainer .m-center {
  margin: 0 auto;
}
.profileContainer .backButton {
  top: 10px;
  left: 20px;
}
.profileContainer .br100 {
  border-radius: 50%;
}
.profileContainer .topProfile {
  position: relative;
  top: 30px;
}
.profileContainer .gradiend {
  background: linear-gradient(
    103.18deg,
    var(--color1) 20.29%,
    var(--color2) 97.77%
  );
}
.MyClubs .ClubElements {
  border: 1px solid rgba(255, 255, 255, 0.089);
}

.MyClubs .opacityClubs {
  opacity: 0.7;
}
</style>