import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fc8c69a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-bar" }
const _hoisted_2 = { class: "level-labels" }
const _hoisted_3 = { class: "init-level" }
const _hoisted_4 = {
  key: 0,
  class: "level"
}
const _hoisted_5 = { class: "last-level" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "progressBar br16 gradiend h-full",
      style: _normalizeStyle({ width: _ctx.barWidth + '%' })
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.firstValue), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelsInRange, (level) => {
        return (_openBlock(), _createElementBlock("span", {
          class: "level",
          key: level
        }, _toDisplayString(level), 1))
      }), 128)),
      (_ctx.showMidLevel)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.midLevel), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.lastValue), 1)
    ])
  ]))
}