import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a2232fb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-6 mb-6" }
const _hoisted_2 = { class: "flex flex-row" }
const _hoisted_3 = { class: "mb-4 mr-2 flex" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex flex-row xxsm:flex-col justify-around" }
const _hoisted_6 = { class: "m-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopUpLight = _resolveComponent("PopUpLight")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("provider")), 1),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPopupInfo = true)),
          class: "flex"
        }, [
          _createElementVNode("img", {
            src: _ctx.infoCircleIcon,
            class: "icon cursor-pointer"
          }, null, 8, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendSelection(_ctx.LevelProvider.PANDORA))),
          class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 cursor-pointer xxsm:mb-2", { selected:  _ctx.provider === _ctx.LevelProvider.PANDORA }])
        }, _toDisplayString(_ctx.$t("taykus")), 3),
        _createElementVNode("div", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendSelection(_ctx.LevelProvider.REDPADEL))),
          class: _normalizeClass(["flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 cursor-pointer", { selected:  _ctx.provider === _ctx.LevelProvider.REDPADEL }])
        }, _toDisplayString(_ctx.$t("redpadel")), 3)
      ])
    ]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showPopupInfo)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPopupInfo = false)),
              customClass: 'width-sm'
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t("provider")), 1),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("the_match_level_will_be_adjusted_according_to_the_selected_level_provider")), 1)
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}